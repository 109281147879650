import React, { useEffect, useState } from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "../../theme/theme"
import Loading from "../../components/atoms/loading"
import Jumbo from "../../components/organisms/jumbo"
import AOS from "aos"
import "aos/dist/aos.css"
import Layout from "../../components/organisms/layout"
import { useStaticQuery, graphql } from "gatsby"
import About from "../../components/organisms/about"
import Visualization from "../../components/organisms/visualization"
import InteractiveVisualization from "../../components/molecules/InteractiveVisualization/InteractiveVisualization"
import Localization from "../../components/organisms/localization"
import Standards from "../../components/organisms/standards"
import Others from "../../components/organisms/others"
import Contact from "../../components/organisms/contact"

const paragraph = `Osiedle Nove Chruściele to nowo powstające osiedle nowoczesnych domów parterowych.
Osiedle realizowane jest w prestiżowej miejscowości - Chruściele - około 3 min. od Centrum Ełku.`

const SecondPage = (props) => {
  const [isLoading, setLoading] = useState(true)
  const data = useStaticQuery(query)

  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (props.location.state !== null) {
      setLoading(false)
    } else {
      document.body.style.overflowY = "hidden"
      setLoading(false)
      document.body.style.overflowY = "scroll"
    }
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <Loading display={isLoading} />
      <Layout pageNumber={2}>
        <Jumbo isAnotherPage={data} />
        <About image={data} paragraph={paragraph} isAnotherPage={true} isColumnForSecondPage={true} />
        <Visualization isAnotherPage={true} />
        <InteractiveVisualization />
        <Localization isAnotherPage={true} />
        <Standards isAnotherPage={true} customPDF={data.datoCmsStandard.standardPdf.url} />
        <Others />
        <Contact />
      </Layout>
    </ThemeProvider>
  )
}

const query = graphql`
  query myQuery {
    allFile(filter: { relativeDirectory: { eq: "SlidesSecondPage" } }, sort: { fields: [base], order: ASC }) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    file(name: { eq: "about_house_secondPage" }) {
      id
      childImageSharp {
        fluid(maxWidth: 1240, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    datoCmsStandard {
      standardPdf {
        url
      }
    }
  }
`

export default SecondPage
